export default {
  qaList: [],
  currentQALock: null,
  indexList: null,
  kendraDataSourceList: null,
  selectedDatasources: [],
  loggingEntries: {},
  autoAnswerEntries: {},
  updatedNotUpdatedEntries: {},
  questionStatusChangedReviewEntries: {},
};
