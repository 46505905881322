import { GET_GKI_KENDRA_DATA_SOURCE_LIST, GET_QUESTION_STATUS_CHANGED_REVIEW_ENTRIES, GET_UPDATED_NOT_UPDATED_ENTRIES, GET_AUTO_ANSWER_ENTRIES, GET_LOGGING_ENTRIES, KNOWLEDGE_INSIGHTS_QA_LIST, SET_KNOWLEDGE_INSIGHTS_CURRENT_QA_LOCK, GET_GKI_INDEX_LIST, SET_SELECTED_DATASOURCE } from '@iso/redux/actionTypes';
import initState from './initialState';

export default function qaListReducer(state = initState, action) {
  switch (action.type) {
    case KNOWLEDGE_INSIGHTS_QA_LIST: {
      return {
        ...state,
        qaList: action.qaList,
      };
    }
    case SET_KNOWLEDGE_INSIGHTS_CURRENT_QA_LOCK: {
      return {
        ...state,
        currentQALock: action.currentQALock,
      };
    }
    case GET_GKI_INDEX_LIST: {
      return {
        ...state,
        indexList: [...action.indexList],
      };
    }
    case GET_GKI_KENDRA_DATA_SOURCE_LIST: {
      return {
        ...state,
        kendraDataSourceList: [...action.kendraDataSourceList],
        //selectedDatasources: [...action.kendraDataSourceList],
      };
    }
    case SET_SELECTED_DATASOURCE: {
      return {
        ...state,
        selectedDatasources: action.selectedDatasources,
      };
    }
    case GET_LOGGING_ENTRIES: {
      return {
        ...state,
        loggingEntries: action.loggingEntries,
      };
    }
    case GET_AUTO_ANSWER_ENTRIES: {
      return {
        ...state,
        autoAnswerEntries: action.autoAnswerEntries,
      };
    }
    case GET_UPDATED_NOT_UPDATED_ENTRIES: {
      return {
        ...state,
        updatedNotUpdatedEntries: action.updatedNotUpdatedEntries,
      };
    }
    case GET_QUESTION_STATUS_CHANGED_REVIEW_ENTRIES: {
      return {
        ...state,
        questionStatusChangedReviewEntries: action.questionStatusChangedReviewEntries,
      };
    }
    default:
      return state;
  }
}
