export default {
  scrollPercentage: null,
  blockingProcessesCount: 0,
  oauthClientOkay: null,
  releaseDate: null,
  embeddedMode: null, // the embedded mode occures when the portal is embedded in iFrame
  thinMode: null, // the thin mode means that the app does not display menus, headers, etc
  maintenance: {
    maintenanceMode: false,
    maintenanceMessage: '',
  },
  sessionInfo: {
    token: '',
    baseUrl: '',
    authCheckCompleted: false,
  },
  userInfo: {
    id: undefined,
    email: undefined,
    department: undefined,
    isLoggedIn: false,
    userType: undefined,
    userName: '',
    userFirstName: '',
    userImage: './images/misc/person.svg',
    genesysCloudPermissions: undefined,
    primaryContactInfo: undefined,
    groups: [],
    userPhoneNo: '',
    ownedGroups: [],
    features: null,
    featuresExtended: null,
  },
  orgInfo: {
    id: undefined,
    orgName: '',
    features: {},
  },
  regionList: [
    {
      displayName: 'Americas (US East)',
      alternateName: 'Americas (US East)',
      baseUrl: 'mypurecloud.com',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAmericasUSEast',
    },
    {
      displayName: 'Americas (US East 2)',
      alternateName: 'FedRamp',
      baseUrl: 'use2.us-gov-pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAmericasUSEast2',
    },
    {
      displayName: 'Americas (US West)',
      alternateName: 'Americas (US West)',
      baseUrl: 'usw2.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAmericasUSWest',
    },
    {
      displayName: 'Americas (Canada)',
      alternateName: 'Americas (Canada)',
      baseUrl: 'cac1.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAmericasCanada',
    },
    {
      displayName: 'Americas (Sao Paulo)',
      alternateName: 'Americas (Sao Paulo)',
      baseUrl: 'sae1.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAmericasSae1',
    },
    {
      displayName: 'Europe (Frankfurt)',
      alternateName: 'EMEA (Frankfurt)',
      baseUrl: 'mypurecloud.de',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionEuropeFrankfurt',
    },
    {
      displayName: 'Europe (Ireland)',
      alternateName: 'EMEA (Dublin)',
      baseUrl: 'mypurecloud.ie',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionEuropeIreland',
    },
    {
      displayName: 'Europe (London)',
      alternateName: 'EMEA (London)',
      baseUrl: 'euw2.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionEuropeLondon',
    },
    {
      displayName: 'Asia Pacific (Mumbai)',
      alternateName: 'Asia Pacific (Mumbai)',
      baseUrl: 'aps1.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAsiaPacificMumbai',
    },
    {
      displayName: 'Asia Pacific (Seoul)',
      alternateName: 'Asia Pacific (Seoul)',
      baseUrl: 'apne2.pure.cloud',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAsiaPacificSeoul',
    },
    {
      displayName: 'Asia Pacific (Sydney)',
      alternateName: 'Asia Pacific (Sydney)',
      baseUrl: 'mypurecloud.com.au',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAsiaPacificSydney',
    },
    {
      displayName: 'Asia Pacific (Tokyo)',
      alternateName: 'Asia Pacific (Tokyo)',
      baseUrl: 'mypurecloud.jp',
      clientId: 'b8a2eda5-9791-4e95-ab8f-b1831e7335f0',
      id: 'signin-home-dropdown-regionAsiaPacificTokyo',
    },
  ],
  userTypes: [
    {
      name: 'ADMIN',
      id: 'ADMIN',
    },
    {
      name: 'Genesys Employee',
      id: 'GENESYS_EMPLOYEE',
    },
    // {
    //   name: 'Tester',
    //   id: 'TESTER',
    // },
    {
      name: 'Partner',
      id: 'PARTNER',
    },
    {
      name: 'Direct Customer',
      id: 'DIRECT_CUSTOMER',
    },
    {
      name: 'Indirect Customer',
      id: 'INDIRECT_CUSTOMER',
    },
  ],
  features: [
    {
      id: 'dashboard-home-tile-ccRequests',
      name: 'PreSales Request Form',
      enabled: true,
      icon: 'ion-help-buoy',
      iconcolor: '#6a02b5',
      title: 'features.ccrequest.title',
      shortTitle: 'features.ccrequest.shortTitle',
      text: 'features.ccrequest.text',
      key: 'ccrequest',
      features: ['ccr_view'],
    },
    {
      id: 'dashboard-home-tile-tools',
      name: 'Tools',
      enabled: true,
      icon: 'ion-wrench',
      iconcolor: '#A4F542',
      title: 'features.tools.title',
      shortTitle: 'features.tools.shortTitle',
      text: 'features.tools.text',
      key: 'tools',
      features: ['tools_view'],
    },
    {
      id: 'dashboard-home-tile-knowledgeInsights',
      name: 'Knowledge Insights',
      enabled: true,
      icon: 'ion-search',
      iconcolor: '#23395D',
      title: 'features.knowledgeinsights.title',
      shortTitle: 'features.knowledgeinsights.shortTitle',
      text: 'features.knowledgeinsights.text',
      key: 'knowledge-insights',
      features: ['gki_view'],
    },
    {
      id: 'dashboard-home-tile-clientapps',
      name: 'Client Apps',
      enabled: true,
      icon: 'ion-android-laptop',
      iconcolor: '#a180f7',
      title: 'features.clientApps.title',
      shortTitle: 'features.clientApps.shortTitle',
      text: 'features.clientApps.text',
      key: 'client-apps',
      features: ['client_apps_view'],
    },
    {
      id: 'dashboard-home-tile-securitydemos',
      name: 'Security/Privacy',
      enabled: true,
      icon: 'ion-locked',
      iconcolor: '#f53131',
      title: 'features.securityDemos.title',
      shortTitle: 'features.securityDemos.shortTitle',
      text: 'features.securityDemos.text',
      key: 'security-demos',
      features: ['security_demos_view', 'sm_view', 'sa_view'],
    },
    {
      id: 'dashboard-home-operations',
      name: 'Operations',
      enabled: true,
      icon: 'ion-settings',
      iconcolor: '#FEAC01',
      title: 'features.operations.title',
      shortTitle: 'features.operations.shortTitle',
      text: 'features.operations.text',
      key: 'operations',
      features: ['ci_view'],
    },
  ],
  currentEnv: {},
  envList: [
    {
      id: 'dev-local-docker',
      pattern: /host.docker.internal/,
      settings: {
        cves: 'http://host.docker.internal:4000/cves',
        securityAdvisor: 'http://host.docker.internal:4000/securityadvisor',
        snowflakeUrl: 'http://host.docker.internal:4000/snowflake',
        securityqa: 'http://host.docker.internal:4000/securityqa',
        adminUrl: 'http://host.docker.internal:4000/admin',
        genesysCloud: 'http://host.docker.internal:4000/gc',
        twilio: 'http://host.docker.internal:4000/twilio',
        jira: 'http://host.docker.internal:4000/jira',
        customerInsights: 'http://host.docker.internal:4000/customerinsights',
        customerInsightsS3reports: 'https://dev-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'http://host.docker.internal:4000/p2pmigration',
        dataDownloader: 'http://host.docker.internal:4000/datadownloader',
        global: 'http://host.docker.internal:4000/global',
        ccrequests: 'http://host.docker.internal:4000/global',
        devForum: 'http://host.docker.internal:4000/devforum',
        logging: 'http://host.docker.internal:4000/logging',
        microsoft: 'http://host.docker.internal:4000/microsoft',
        gdprdemo: 'http://host.docker.internal:4000/gdprdemo',
        recordingsdemo: 'http://host.docker.internal:4000/recordingsdemo',
        globalWs: 'TODO',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/Dev/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/Dev/Attachments',
      },
    },
    {
      id: 'dev-local',
      pattern: /localhost/,
      settings: {
        cves: 'http://localhost:4000/cves',
        securityAdvisor: 'http://localhost:4000/securityadvisor',
        aws: 'http://localhost:4000/aws',
        snowflakeUrl: 'http://localhost:4000/snowflake',
        securityqa: 'http://localhost:4000/securityqa',
        adminUrl: 'http://localhost:4000/admin',
        genesysCloud: 'http://localhost:4000/gc',
        twilio: 'http://localhost:4000/twilio',
        jira: 'http://localhost:4000/jira',
        customerInsights: 'http://localhost:4000/customerinsights',
        customerInsightsS3reports: 'https://dev-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'http://localhost:4000/p2pmigration',
        dataDownloader: 'http://localhost:4000/datadownloader',
        global: 'http://localhost:4000/global',
        ccrequests: 'http://localhost:4000/ccrequests',
        knowledgeInsights: 'http://localhost:4000/gki',
        gkiOffice: 'http://localhost:4000/gki-office',
        qas: 'http://localhost:4000/qas',
        rfpio: 'http://localhost:4000/rfpio',
        aha: 'http://localhost:4000/aha',
        devForum: 'http://localhost:4000/devforum',
        logging: 'http://localhost:4000/logging',
        higherlogic: 'http://localhost:4000/higherlogic',
        microsoft: 'http://localhost:4000/microsoft',
        gdprdemo: 'http://localhost:4000/gdprdemo',
        recordingsdemo: 'http://localhost:4000/recordingsdemo',
        // globalWs: 'ws://localhost:3002',
        globalWs: 'wss://oxc2pw5g5g.execute-api.eu-central-1.amazonaws.com/dev',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/Dev/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/Dev/Attachments',
      },
    },
    {
      id: 'dev',
      pattern: /dev.ccportal.genesys.com/,
      settings: {
        cves: 'https://api.dev.ccportal.genesys.com/cves',
        securityAdvisor: 'https://api.dev.ccportal.genesys.com/securityadvisor',
        aws: 'https://api.dev.ccportal.genesys.com/aws',
        snowflakeUrl: 'https://api.dev.ccportal.genesys.com/snowflake',
        securityqa: 'https://api.dev.ccportal.genesys.com/securityqa',
        adminUrl: 'https://api.dev.ccportal.genesys.com/admin',
        genesysCloud: 'https://api.dev.ccportal.genesys.com/gc',
        twilio: 'https://api.dev.ccportal.genesys.com/twilio',
        jira: 'https://api.dev.ccportal.genesys.com/jira',
        customerInsights: 'https://api.dev.ccportal.genesys.com/customerinsights',
        customerInsightsS3reports: 'https://dev-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'https://api.dev.ccportal.genesys.com/p2pmigration',
        dataDownloader: 'https://api.dev.ccportal.genesys.com/datadownloader',
        global: 'https://api.dev.ccportal.genesys.com/global',
        ccrequests: 'https://api.dev.ccportal.genesys.com/ccrequests',
        knowledgeInsights: 'https://api.dev.ccportal.genesys.com/gki',
        gkiOffice: 'https://api.dev.ccportal.genesys.com/gki-office',
        qas: 'https://api.dev.ccportal.genesys.com/qas',
        rfpio: 'https://api.dev.ccportal.genesys.com/rfpio',
        aha: 'https://api.dev.ccportal.genesys.com/aha',
        devForum: 'https://api.dev.ccportal.genesys.com/devforum',
        logging: 'https://api.dev.ccportal.genesys.com/logging',
        higherlogic: 'https://api.dev.ccportal.genesys.com/higherlogic',
        microsoft: 'https://api.dev.ccportal.genesys.com/microsoft',
        gdprdemo: 'https://api.dev.ccportal.genesys.com/gdprdemo',
        recordingsdemo: 'https://api.dev.ccportal.genesys.com/recordingsdemo',
        globalWs: 'wss://oxc2pw5g5g.execute-api.eu-central-1.amazonaws.com/dev',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/Dev/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/Dev/Attachments',
      },
    },
    {
      id: 'qa',
      pattern: /qa.ccportal.genesys.com/,
      settings: {
        cves: 'https://api.qa.ccportal.genesys.com/cves',
        securityAdvisor: 'https://api.qa.ccportal.genesys.com/securityadvisor',
        aws: 'https://api.qa.ccportal.genesys.com/aws',
        snowflakeUrl: 'https://api.qa.ccportal.genesys.com/snowflake',
        securityqa: 'https://api.qa.ccportal.genesys.com/securityqa',
        adminUrl: 'https://api.qa.ccportal.genesys.com/admin',
        genesysCloud: 'https://api.qa.ccportal.genesys.com/gc',
        twilio: 'https://api.qa.ccportal.genesys.com/twilio',
        jira: 'https://api.qa.ccportal.genesys.com/jira',
        customerInsights: 'https://api.qa.ccportal.genesys.com/customerinsights',
        customerInsightsS3reports: 'https://qa-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'https://api.qa.ccportal.genesys.com/p2pmigration',
        dataDownloader: 'https://api.qa.ccportal.genesys.com/datadownloader',
        global: 'https://api.qa.ccportal.genesys.com/global',
        ccrequests: 'https://api.qa.ccportal.genesys.com/ccrequests',
        knowledgeInsights: 'https://api.qa.ccportal.genesys.com/gki',
        gkiOffice: 'https://api.qa.ccportal.genesys.com/gki-office',
        qas: 'https://api.qa.ccportal.genesys.com/qas',
        rfpio: 'https://api.qa.ccportal.genesys.com/rfpio',
        aha: 'https://api.qa.ccportal.genesys.com/aha',
        devForum: 'https://api.qa.ccportal.genesys.com/devforum',
        logging: 'https://api.qa.ccportal.genesys.com/logging',
        higherlogic: 'https://api.qa.ccportal.genesys.com/higherlogic',
        microsoft: 'https://api.qa.ccportal.genesys.com/microsoft',
        gdprdemo: 'https://api.qa.ccportal.genesys.com/gdprdemo',
        recordingsdemo: 'https://api.qa.ccportal.genesys.com/recordingsdemo',
        globalWs: 'wss://h9zq4feyqa.execute-api.eu-central-1.amazonaws.com/qa',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/QA/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/QA/Attachments',
      },
    },
    {
      id: 'admin',
      pattern: /admin.ccportal.genesys.com/,
      settings: {
        cves: 'https://api.admin.ccportal.genesys.com/cves',
        securityAdvisor: 'https://api.admin.ccportal.genesys.com/securityadvisor',
        aws: 'https://api.ccportal.genesys.com/aws',
        snowflakeUrl: 'https://api.ccportal.genesys.com/snowflake',
        securityqa: 'https://api.ccportal.genesys.com/securityqa',
        adminUrl: 'https://api.ccportal.genesys.com/admin',
        genesysCloud: 'https://api.ccportal.genesys.com/gc',
        twilio: 'https://api.ccportal.genesys.com/twilio',
        jira: 'https://api.ccportal.genesys.com/jira',
        customerInsights: 'https://api.ccportal.genesys.com/customerinsights',
        customerInsightsS3reports: 'https://prod-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'https://api.ccportal.genesys.com/p2pmigration',
        dataDownloader: 'https://api.ccportal.genesys.com/datadownloader',
        global: 'https://api.ccportal.genesys.com/global',
        ccrequests: 'https://api.ccportal.genesys.com/ccrequests',
        knowledgeInsights: 'https://api.ccportal.genesys.com/gki',
        gkiOffice: 'https://api.ccportal.genesys.com/gki-office',
        qas: 'https://api.ccportal.genesys.com/qas',
        rfpio: 'https://api.ccportal.genesys.com/rfpio',
        aha: 'https://api.ccportal.genesys.com/aha',
        devForum: 'https://api.ccportal.genesys.com/devforum',
        logging: 'https://api.ccportal.genesys.com/logging',
        higherlogic: 'https://api.ccportal.genesys.com/higherlogic',
        microsoft: 'https://api.ccportal.genesys.com/microsoft',
        gdprdemo: 'https://api.ccportal.genesys.com/gdprdemo',
        recordingsdemo: 'https://api.ccportal.genesys.com/recordingsdemo',
        globalWs: 'wss://fok184oaa2.execute-api.eu-central-1.amazonaws.com/prod',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/Attachments',
      },
    },
    {
      id: 'prod',
      pattern: undefined, // a default environment
      settings: {
        cves: 'https://api.ccportal.genesys.com/cves',
        securityAdvisor: 'https://api.ccportal.genesys.com/securityadvisor',
        aws: 'https://api.ccportal.genesys.com/aws',
        snowflakeUrl: 'https://api.ccportal.genesys.com/snowflake',
        securityqa: 'https://api.ccportal.genesys.com/securityqa',
        adminUrl: 'https://api.ccportal.genesys.com/admin',
        genesysCloud: 'https://api.ccportal.genesys.com/gc',
        twilio: 'https://api.ccportal.genesys.com/twilio',
        jira: 'https://api.ccportal.genesys.com/jira',
        customerInsights: 'https://api.ccportal.genesys.com/customerinsights',
        customerInsightsS3reports: 'https://prod-customerinsights-workers-results.s3.eu-central-1.amazonaws.com',
        p2pmigration: 'https://api.ccportal.genesys.com/p2pmigration',
        dataDownloader: 'https://api.ccportal.genesys.com/datadownloader',
        global: 'https://api.ccportal.genesys.com/global',
        ccrequests: 'https://api.ccportal.genesys.com/ccrequests',
        knowledgeInsights: 'https://api.ccportal.genesys.com/gki',
        gkiOffice: 'https://api.ccportal.genesys.com/gki-office',
        qas: 'https://api.ccportal.genesys.com/qas',
        rfpio: 'https://api.ccportal.genesys.com/rfpio',
        aha: 'https://api.ccportal.genesys.com/aha',
        devForum: 'https://api.ccportal.genesys.com/devforum',
        logging: 'https://api.ccportal.genesys.com/logging',
        higherlogic: 'https://api.ccportal.genesys.com/higherlogic',
        microsoft: 'https://api.ccportal.genesys.com/microsoft',
        gdprdemo: 'https://api.ccportal.genesys.com/gdprdemo',
        recordingsdemo: 'https://api.ccportal.genesys.com/recordingsdemo',
        globalWs: 'wss://fok184oaa2.execute-api.eu-central-1.amazonaws.com/prod',
        SP_ROOT_FOLDER_ASSESSMENTS: 'PreSales Portal/Assessments',
        SP_ROOT_FOLDER_ATTACHMENTS: 'PreSales Portal/Attachments',
      },
    },
  ],
  templateCategories: [
    {
      id: 1,
      name: 'User created',
    },
    {
      id: 2,
      name: 'Vertical Templates',
    },
    {
      id: 3,
      name: 'Use Cases Templates',
    },
  ],
  permissionsInfo: [
    {
      defId: 'chatWidgets',
      permissionsList: ['widgets:deployment:add', 'webchat:deployment:create', 'widgets:deployment:view', 'webchat:deployment:read'],
    },
    {
      defId: 'emailDomains',
      permissionsList: ['routing:email:manage'],
    },
    {
      defId: 'flows',
      permissionsList: ['architect:flow:add', 'architect:flow:view', 'architect:flow:edit', 'architect:flow:unlock', 'architect:flow:publish'],
    },
    {
      defId: 'locations',
      permissionsList: ['directory:location:edit', 'directory:location:add'],
    },
    {
      defId: 'queues',
      permissionsList: ['routing:queue:add', 'routing:queue:view', 'routing:queue:edit'],
    },
    {
      defId: 'schedules',
      permissionsList: ['telephony:plugin:all'],
    },
    {
      defId: 'sites',
      permissionsList: ['telephony:sites:add', 'telephony:sites:view', 'telephony:sites:edit'],
    },
    {
      defId: 'skills',
      permissionsList: ['routing:skill:manage'],
    },
    {
      defId: 'trunks',
      permissionsList: ['telephony:plugin:all'],
    },
    {
      defId: 'users',
      permissionsList: ['directory:user:add', 'directory:user:edit', 'directory:user:view'],
    },
    {
      defId: 'wrapUpCodes',
      permissionsList: ['routing:wrapupCode:add'],
    },
    {
      defId: 'scripts',
      permissionsList: ['scripter:publishedScript:add', 'scripter:publishedScript:delete', 'scripter:publishedScript:edit', 'scripter:publishedScript:view', 'scripter:script:add', 'scripter:script:delete', 'scripter:script:edit', 'scripter:script:view'],
    },
  ],
};
